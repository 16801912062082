import React from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {Provider} from "react-redux";
import {store} from './redux'
import Home from "./screen/home/Home";
import NotFound from "./screen/home/NotFound";
import Wa from "./screen/wa/Wa";
import WaAgent from "./screen/wa/WaAgent";
import NewVehicleVariant from "./screen/newVehicleVariant/NewVehicleVariant";
import NewVehicleVariantDetail from "./screen/newVehicleVariantDetail/NewVehicleVariantDetail";
import NewVehicleVariantDetailInformation from "./screen/newVehicleVariantDetailInformation/NewVehicleVariantDetailInformation";
import NewVehicleVariantCreditSimulation from "./screen/newVehicleVariantDetailCreditSimulation/NewVehicleVariantCreditSimulation";
import NewVehicleVariantCreditSimulationOld from "./screen/newVehicleVariantDetailInformation/NewVehicleVariantCreditSimulation";
import NewVehicleVariantDetailCredit from "./screen/newVehicleVariantDetailCredit/NewVehicleVariantDetailCredit";
import NewVehicleVariantDetailFormCredit from "./screen/newVehicleVariantDetailCredit/NewVehicleVariantDetailFormCredit";
import NewVehicleVariantDetailCash from "./screen/newVehicleVariantDetailCash/NewVehicleVariantDetailCash";
import Promo from "./screen/promo/Promo";
import Spk from "./screen/spk/Spk";
import UpdateSpk from "./screen/spk/UpdateSpk";
import Track from "./screen/track/Track";
import Booking from "./screen/booking/Booking";
import Live from "./screen/microPage/Live";
import MicroPage from "./screen/microPage/MicroPage";
import Pricelist from "./screen/microPage/Pricelist";
import CreatePricelist from "./screen/microPage/CreatePricelist";
// import Express from "./screen/express/Express";
// import AdiraExpress from "./screen/express/AdiraExpress";
import Ad1xExpress from "./screen/express/Ad1xExpress";
// import OrderMemo from "./screen/express/OrderMemo";
import PushOffer from "./screen/express/PushOffer";
import Login from "./screen/auth/Login";
import Register from "./screen/auth/Register";
import MyProfile from "./screen/myProfile/MyProfile";
import MyBookmark from "./screen/myProfile/MyBookmark";
import MyBiodata from "./screen/myProfile/MyBiodata";
import MyPromo from "./screen/myProfile/MyPromo";
import MyDeal from "./screen/myProfile/MyDeal";
import MyOrder from "./screen/myProfile/MyOrder";
import ResetPassword from "./screen/auth/ResetPassword";
import PrivacyPage from "./screen/microPage/PrivacyPage";
import MokasSale from "./screen/mokas/MokasSale";
import TradeInVehicle from "./screen/mokas/TradeInVehicle";
import Mokas from "./screen/mokas/Mokas";
import DetailMokas from "./screen/mokas/DetailMokas";

import UsedMokas from "./screen/usedMokas/UsedMokas";
// import UsedMokasDetail from "./screen/usedMokasDetail/UsedMokasDetail";

// import Test from "./screen/test/Test";
import MomotorDev from "./screen/dev/MomotorDev";


function App() {
    return (
        <Provider store={store}>
            <Router>
                <Routes>
                    {/*<Route path={"/test"}><Test/></Route>*/}
                    <Route path={"/dev-momotor"} caseSensitive={false} element={<MomotorDev/>}/>
                    <Route path={"/baru/:area/variant/:param/:param2"} caseSensitive={false} element={<NewVehicleVariant/>}/>
                    <Route path={"/baru/:area/variant/:param"} caseSensitive={false} element={<NewVehicleVariant/>}/>
                    <Route path={"/baru/:area/variant"} caseSensitive={false} element={<NewVehicleVariant/>}/>
                    <Route path={"/baru/:area/:productcode/kredit"} caseSensitive={false} element={<NewVehicleVariantDetailCredit/>}/>
                    <Route path={"/baru/:area/:productcode/tunai"} caseSensitive={false} element={<NewVehicleVariantDetailCash/>}/>
                    <Route path={"/baru/:area/:productcode/detail"} caseSensitive={false} element={<NewVehicleVariantDetailInformation/>}/>
                    <Route path={"/baru/:area/:productcode/simulasi-kredit"} caseSensitive={false} element={<NewVehicleVariantCreditSimulation/>}/>
                    <Route path={"/baru/:area/:productcode/simulasi-kredit-old"} caseSensitive={false} element={<NewVehicleVariantCreditSimulationOld/>}/>
                    <Route path={"/baru/:area/:productcode"} caseSensitive={false} element={<NewVehicleVariantDetail/>}/>
                    <Route path={"/mokas"} caseSensitive={false} element={<UsedMokas/>}/>
                    <Route path={"/track"} caseSensitive={false} element={<Track/>}/>
                    {/*<Route path={"/express"} caseSensitive={false} element={<Express/>}/>*/}
                    {/*<Route path={"/adira-express"} caseSensitive={false} element={<AdiraExpress/>}/>*/}
                    <Route path={"/ad1x"} caseSensitive={false} element={<Ad1xExpress/>}/>
                    {/*<Route path={"/order-memo"} caseSensitive={false} element={<OrderMemo/>}/>*/}
                    <Route path={"/booking-service/:dealerCode"} caseSensitive={false} element={<Booking/>}/>
                    <Route path={"/booking-service"} caseSensitive={false} element={<Booking/>}/>
                    <Route path={"/spk/:offercode"} caseSensitive={false} element={<Spk/>}/>
                    <Route path={"/spk"} caseSensitive={false} element={<Spk/>}/>
                    <Route path={"/update-spk/:offercode"} caseSensitive={false} element={<UpdateSpk/>}/>
                    <Route path={"/push-offer/:offercode"} caseSensitive={false} element={<PushOffer/>}/>
                    <Route path={"/p/:micropagecode"} caseSensitive={false} element={<MicroPage/>}/>
                    <Route path={"/pl/:code"} caseSensitive={false} element={<Pricelist/>}/>
                    <Route path={"/create-pricelist"} caseSensitive={false} element={<CreatePricelist/>}/>
                    <Route path={"/wa"} caseSensitive={false} element={<Wa/>}/>
                    <Route path={"/wa/:agentCode"} caseSensitive={false} element={<WaAgent/>}/>
                    <Route path={"/aju-kredit/:offercode"} caseSensitive={false} element={<NewVehicleVariantDetailFormCredit/>}/>
                    <Route path={"/aju-kredit"} caseSensitive={false} element={<NotFound/>}/>
                    <Route path={"/notfound"} caseSensitive={true} element={<NotFound/>}/>
                    <Route path={"/:area/promo"} caseSensitive={true} element={<Promo/>}/>
                    <Route path={"/:area"} caseSensitive={true} element={<Home/>}/>
                    <Route path={"/login"} caseSensitive={false} element={<Login/>}/>
                    <Route path={"/register"} caseSensitive={false} element={<Register/>}/>
                    <Route path={"/my-profile"} caseSensitive={false} element={<MyProfile/>}/>
                    <Route path={"/my-bookmark"} caseSensitive={false} element={<MyBookmark/>}/>
                    <Route path={"/my-promo"} caseSensitive={false} element={<MyPromo/>}/>
                    <Route path={"/my-biodata"} caseSensitive={false} element={<MyBiodata/>}/>
                    <Route path={"/my-deal"} caseSensitive={false} element={<MyDeal/>}/>
                    <Route path={"/my-order"} caseSensitive={false} element={<MyOrder/>}/>
                    <Route path={"/reset-password"} caseSensitive={false} element={<ResetPassword/>}/>
                    <Route path={"/privasi"} caseSensitive={false} element={<PrivacyPage/>}/>
                    <Route path={"/live"} caseSensitive={false} element={<Live/>}/>
                    <Route path={"/jual-motor"} caseSensitive={false} element={<MokasSale/>}/>
                    <Route path={"/tukar-tambah-motor"} caseSensitive={false} element={<TradeInVehicle/>}/>
                    <Route path={"/:area/mokas"} caseSensitive={false} element={<Mokas/>}/>
                    <Route path={"/mokas/:productcode"} caseSensitive={false} element={<DetailMokas/>}/>
                    <Route path={"/"} caseSensitive={true} element={<Home/>}/>
                    <Route path="*" element={<NotFound/>}/>
                </Routes>
            </Router>
        </Provider>
    );
}

export default App;
