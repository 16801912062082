import React, {Component} from "react";
import {Buffer} from "buffer";
import {NewVehicleVariantCashStates} from "./types/NewVehicleVariantDetailCashTypes";
import {withRouter} from "../../hoc/withRouter";
import SimpleLayout from "../../components/layouts/SimpleLayout";
import CashSubmissionLayout from "../../components/layouts/CashSubmissionLayout";
import {trimitraCatalogServices} from "../../services/trimitraCatalog/trimitraCatalogServices";
import {vehicleServices} from "../../services/autotrimitra/vehicleServices";
import variantCodeUrlFormat from "../../helper/variantCodeUrlFormat";
import './NewVehicleVariantDetailCash.css';
import ListPromoModal from "../../components/promo/ListPromoModal";
import AreaProvinceSelect from "../../components/area/AreaProvinceSelect";
import AreaCitySelect from "../../components/area/AreaCitySelect";
import AreaDistrictSelect from "../../components/area/AreaDistrictSelect";
import AreaSubdistrictSelect from "../../components/area/AreaSubdistrictSelect";
import currencyFormat from "../../helper/currencyFormat";
import {offerApiServices} from "../../services/offer/OfferApiService";
import {promoServices} from "../../services/promo/PromoService";
import {userServices} from "../../services/amartaVip/UserServices";
import TextArea from "antd/es/input/TextArea";
import Button from "antd/lib/button";
import Card from "antd/lib/card";
import Checkbox from "antd/lib/checkbox";
import Col from "antd/lib/col";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Image from "antd/lib/image";
import Modal from "antd/lib/modal";
import Row from "antd/lib/row";
import Spin from "antd/lib/spin";
import Typography from "antd/lib/typography";
import notification from "antd/lib/notification";
import SearchOutlined from '@ant-design/icons/SearchOutlined';
import ResumeMiniProduct from "../../components/product/ResumeMiniProduct";
import {trackLogServices} from "../../services/track/TrackLogService";
import {Divider, Dropdown, Popover, Space, Upload} from "antd";
import type {MenuProps} from 'antd';
import {DownOutlined, ExclamationCircleOutlined, InfoCircleOutlined} from "@ant-design/icons";
import Tag from "antd/lib/tag";
import Select from "antd/lib/select";
import IdcardOutlined from "@ant-design/icons/IdcardOutlined";
import CropIdCardImage from "../../components/image/CropIdCardImage";
import Collapse from "antd/lib/collapse";
import {cdnApiService} from "../../services/trimitraCdn/CdnApiService";
import {ocrApiService} from "../../services/trimitraCdn/OcrApiService";
import {authApiService} from "../../services/amartaVip/AuthApiService";
import {baseUrlCdnUpload} from "../../config/apiConfig/apiConfig";
import UploadOutlined from "@ant-design/icons/UploadOutlined";
import SnippetsOutlined from "@ant-design/icons/SnippetsOutlined";
import {crmService} from "../../services/b2b/crmService";
import reformatPhoneNumber from "../../helper/reformatPhoneNumber";
import Result from "antd/lib/result";
import Tooltip from "antd/lib/tooltip";
import CopyOutlined from "@ant-design/icons/CopyOutlined";
import message from "antd/lib/message";
import Alert from "antd/lib/alert";
import {Link} from "react-router-dom";
import DownloadOutlined from "@ant-design/icons/DownloadOutlined";

const {Panel} = Collapse;

const debug = false;

class NewVehicleVariantDetailCash extends Component<any, NewVehicleVariantCashStates> {
    private readonly initState!: NewVehicleVariantCashStates;
    private readonly areaCode: any;

    constructor(props: any) {
        super(props);

        const {match, location} = this.props;
        this.areaCode = match.params.area;

        const urlVariantColor = match.params.productcode.split("-");
        const variantCode = variantCodeUrlFormat(urlVariantColor[0]);
        const variantColorCode = urlVariantColor[1];

        document.title = `Amartahonda | Cash | Detail Variant Kendaraan Baru | ${match.params.area} | ${variantCode}`;

        const params = new URLSearchParams(location.search);
        const stockarea: any = params.get('stockarea');

        this.initState = {
            processFetchBiodata: false,
            extraTextNeedLogin: "",
            submitting: false,
            areaCode: this.areaCode,
            variantCode: variantCode,
            variantColorCode: variantColorCode,
            listNewVariantColor: [],
            chosenNewVariantColor: {},
            chosenNewVariantData: {},
            fullName: "",
            phoneNumber: "",
            alternativePhoneNumber: "",
            idCardNumber: "",
            familyCardNumber: "",
            isWhatsappAvailable: true,
            takeInDealer: false,
            freezeStatus: false,
            offerFor: "owner",
            fullNameOrderMaker: "",
            phoneNumberOrderMaker: "",
            differentAddress: false,
            completeFetchVariant: false,
            customerProvinceCode: "",
            customerProvinceName: "",
            customerCityCode: "",
            customerCityName: "",
            customerDistrictCode: "",
            customerDistrictName: "",
            customerSubdistrictCode: "",
            customerSubdistrictName: "",
            customerPostalCode: "",
            customerDisablePostalCode: false,
            customerHamlet: "",
            customerNeighbourhood: "",
            customerAddress: "",
            deliveryProvinceCode: "",
            deliveryProvinceName: "",
            deliveryCityCode: "",
            deliveryCityName: "",
            deliveryDistrictCode: "",
            deliveryDistrictName: "",
            deliverySubdistrictCode: "",
            deliverySubdistrictName: "",
            deliveryPostalCode: "",
            deliveryDisablePostalCode: false,
            deliveryHamlet: "",
            deliveryNeighbourhood: "",
            deliveryAddress: "",
            modalPromoShow: false,
            usedPromoData: {},
            showAgentCode: true,
            agentCode: "",
            agentName: "",
            offerNote: (stockarea) ? `stock area: ${stockarea}` : "",
            pageShow: 1,
            offerCode: "",
            finalLoadingModal: false,
            listColor: [],
            alternativeColor: {
                code: "SAME_AS_ORDER",
                name: "warna sesuai pesanan"
            },
            additionalOrderData: {
                cost: 0,
                note: "",
            },
            showOldUploadRawImage: false,
            idCardImageUrl: "",
            showModalUploadIdCardOwner: false,
            base64IdCardOwner: "",
            amartaVipUid: "",
            amartaVipType: "",
            showModalNeedLogin: false,
        }
        this.state = {...this.initState}
    }

    addLeadingZeros = (num: any, totalLength: any) => {
        return String(num).padStart(totalLength, '0');
    }

    onFieldChange = <T extends keyof Pick<any, "offerFor" | "fullName" | "idCardNumber" | "phoneNumber" | "customerHamlet" | "customerNeighbourhood" | "deliveryHamlet" | "deliveryNeighbourhood" | "deliveryPostalCode" | "alternativePhoneNumber" | "familyCardNumber" | "customerPostalCode" | "deliveryAddress" | "customerAddress" | "fullNameOrderMaker" | "phoneNumberOrderMaker">>(target: T, value: string) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "offerFor":
                currentState.offerFor = value;
                break;
            case "fullName":
                currentState.fullName = value?.toUpperCase()?.replace(/[^a-zA-Z0-9\s]/g, '');
                break;
            case "idCardNumber":
                currentState.idCardNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "phoneNumber":
                currentState.phoneNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "phoneNumberOrderMaker":
                currentState.phoneNumberOrderMaker = value.replace(/[^0-9.]/g, '');
                break;
            case "alternativePhoneNumber":
                currentState.alternativePhoneNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "familyCardNumber":
                currentState.familyCardNumber = value.replace(/[^0-9.]/g, '');
                break;
            case "customerPostalCode":
                currentState.customerPostalCode = value.replace(/[^0-9.]/g, '');
                break;
            case "customerAddress":
                currentState.customerAddress = value;
                break;
            case "deliveryAddress":
                currentState.deliveryAddress = value;
                break;
            case "deliveryPostalCode":
                currentState.deliveryPostalCode = value.replace(/[^0-9.]/g, '');
                break;
            case "fullNameOrderMaker":
                currentState.fullNameOrderMaker = value?.toUpperCase()?.replace(/[^a-zA-Z0-9\s]/g, '');
                break;
            case "customerHamlet":
                currentState.customerHamlet = value?.replace(/[^0-9.]/g, '');
                break;
            case "customerNeighbourhood":
                currentState.customerNeighbourhood = value?.replace(/[^0-9.]/g, '');
                break;
            case "deliveryHamlet":
                currentState.deliveryHamlet = value?.replace(/[^0-9.]/g, '');
                break;
            case "deliveryNeighbourhood":
                currentState.deliveryNeighbourhood = value?.replace(/[^0-9.]/g, '');
                break;
        }

        if (target === 'phoneNumber' || target === 'alternativePhoneNumber' || target === 'phoneNumberOrderMaker') {
            if (currentState.phoneNumber.length > 1) {
                if (currentState.phoneNumber.substring(0, 2) !== '08') {
                    currentState.phoneNumber = "";
                }
            }
            if (currentState.alternativePhoneNumber.length > 1) {
                if (currentState.alternativePhoneNumber.substring(0, 2) !== '08') {
                    currentState.alternativePhoneNumber = "";
                }
            }
            if (currentState.phoneNumberOrderMaker.length > 1) {
                if (currentState.phoneNumberOrderMaker.substring(0, 2) !== '08') {
                    currentState.phoneNumberOrderMaker = "";
                }
            }
        }

        this.setState({
            ...currentState,
        });
    }

    onBooleanFieldChange = <T extends keyof Pick<any, "isWhatsappAvailable" | "takeInDealer" | "differentAddress">>(target: T, value: boolean) => {
        const currentState: any = {...this.state};
        switch (target) {
            case "isWhatsappAvailable":
                // currentState.isWhatsappAvailable = value;
                break;
        }
        switch (target) {
            case "takeInDealer":
                currentState.takeInDealer = value;
                break;
        }
        switch (target) {
            case "differentAddress":
                currentState.differentAddress = value;
                break;
        }
        this.setState({
            ...currentState,
        })
    }

    onAreaProvinceChange = (data: any) => {
        this.setState({
            customerProvinceCode: data.value,
            customerProvinceName: data.children,
            customerCityCode: "",
            customerCityName: "",
            customerDistrictCode: "",
            customerDistrictName: "",
            customerSubdistrictCode: "",
            customerSubdistrictName: "",
            customerPostalCode: ""
        });
    }

    onAreaCityChange = (data: any) => {
        this.setState({
            customerCityCode: data.value,
            customerCityName: data.children,
            customerDistrictCode: "",
            customerDistrictName: "",
            customerSubdistrictCode: "",
            customerSubdistrictName: "",
            customerPostalCode: ""
        });
    }

    onAreaDistrictChange = (data: any) => {
        this.setState({
            customerDistrictCode: data.value,
            customerDistrictName: data.children,
            customerSubdistrictCode: "",
            customerSubdistrictName: "",
            customerPostalCode: ""
        });
    }

    onAreaSubdistrictChange = (data: any) => {
        this.setState({
            customerSubdistrictCode: data.value,
            customerSubdistrictName: data.children,
            customerPostalCode: data.postalCode,
            customerDisablePostalCode: !!(data.postalCode)
        });
    }

    onDeliveryProvinceChange = (data: any) => {
        this.setState({
            deliveryProvinceCode: data.value,
            deliveryProvinceName: data.children,
            deliveryCityCode: "",
            deliveryCityName: "",
            deliveryDistrictCode: "",
            deliveryDistrictName: "",
            deliverySubdistrictCode: "",
            deliverySubdistrictName: "",
            deliveryPostalCode: ""
        });
    }

    onDeliveryCityChange = (data: any) => {
        this.setState({
            deliveryCityCode: data.value,
            deliveryCityName: data.children,
            deliveryDistrictCode: "",
            deliveryDistrictName: "",
            deliverySubdistrictCode: "",
            deliverySubdistrictName: "",
            deliveryPostalCode: ""
        });
    }

    onDeliveryDistrictChange = (data: any) => {
        this.setState({
            deliveryDistrictCode: data.value,
            deliveryDistrictName: data.children,
            deliverySubdistrictCode: "",
            deliverySubdistrictName: "",
            deliveryPostalCode: ""
        });
    }

    onDeliverySubdistrictChange = (data: any) => {
        this.setState({
            deliverySubdistrictCode: data.value,
            deliverySubdistrictName: data.children,
            deliveryPostalCode: data.postalCode,
            deliveryDisablePostalCode: !!(data.postalCode)
        });
    }

    checkBlacklistContact = async () => {
        let aaa = false;
        await userServices.checkBlacklistPhoneNumber(
            {
                phoneNumber: this.state.phoneNumber
            }
        ).then(dataCheck => {
            aaa = true;
        }).catch(e => {
            aaa = false;
        });

        return aaa;
    }

    onClickNext = async (data: any) => {
        if (!debug) {
            if (data === 2) {
                if (this.state.offerFor === "maker") {
                    if (!this.state.amartaVipUid) {
                        await this.promisedSetState({
                            showModalNeedLogin: true,
                            extraTextNeedLogin: "",
                        });
                        return;
                    }

                    if (!this.state.fullNameOrderMaker || !this.state.phoneNumberOrderMaker) {
                        Modal.error({title: 'Proses Gagal', content: "Lengkapi nama dan nomor telpon pemesan"});
                        return;
                    }
                }

                if (!this.state.phoneNumber) {
                    Modal.error({title: 'Proses Gagal', content: "Lengkapi nomor telpon pemilik"});
                    return;
                }

                this.setState({
                    submitting: true
                });

                if (this.state.offerFor === "owner") {
                    const checkBlacklist = await this.checkBlacklistContact();
                    if (checkBlacklist) {
                        Modal.error({
                            title: 'Proses Gagal',
                            content: "Nomor telpon pemilik tidak dapat digunakan, hubungi admin untuk info lebih lanjut"
                        });
                        this.setState({
                            submitting: false
                        });
                        return;
                    }
                }

                this.setState({
                    submitting: false
                });

                try {
                    const resultCrm: any = crmService.checkPhoneNumberCrm({
                        name: "Customer",
                        event: "Check Phone Number",
                        source: "amartahonda",
                        phoneNumber: reformatPhoneNumber(this.state.phoneNumber),
                    });
                    if (resultCrm?.data?.data?.check?.isRegistered) {
                        this.setState({
                            isWhatsappAvailable: true
                        });
                    } else {
                        this.setState({
                            isWhatsappAvailable: false
                        });
                    }
                } catch (e) {
                    this.setState({
                        isWhatsappAvailable: false
                    });
                }

            }

            if (data === 3) {
                let warnNotice = "";
                if (!this.state.idCardNumber) {
                    warnNotice += "Nomor KTP,";
                }
                if (!this.state.fullName) {
                    warnNotice += "Nama Lengkap,";
                }
                if (!this.state.familyCardNumber) {
                    warnNotice += "Nomor Kartu Keluarga,";
                }
                if (!this.state.phoneNumber) {
                    warnNotice += "Nomor Telpon,";
                }
                if (!this.state.idCardImageUrl) {
                    warnNotice += "Upload Gambar KTP,";
                }
                if (!this.state.customerProvinceCode) {
                    warnNotice += "Provinsi, \n";
                }
                if (!this.state.customerCityCode) {
                    warnNotice += "Kota,";
                }
                if (!this.state.customerDistrictCode) {
                    warnNotice += "Kecamatan,";
                }
                if (!this.state.customerSubdistrictCode) {
                    warnNotice += "Kelurahan,";
                }
                if (this.state.customerPostalCode?.trim().length !== 5) {
                    warnNotice += "Kode Pos tidak valid,";
                }
                if (!this.state.customerAddress) {
                    warnNotice += "Alamat Identitas,";
                }
                if (!this.state.customerNeighbourhood) {
                    warnNotice += "RW,";
                }
                if (!this.state.customerHamlet) {
                    warnNotice += "RT,";
                }

                if (this.state.idCardNumber.length !== 16) {
                    const objError = warnNotice.split(",");
                    Modal.error({
                        title: 'Proses Gagal',
                        content: (<div>
                            Mohon Lengkapi: <br/><br/>
                            {objError.map(function (itemError, i) {
                                return <div key={i}>{itemError}</div>;
                            })}
                        </div>)
                    });
                    return false;
                }
                if (warnNotice !== "") {
                    const objError = warnNotice.split(",");
                    Modal.error({
                        title: 'Proses Gagal',
                        content: (<div>
                            Mohon Lengkapi data-data berikut: <br/><br/>
                            {objError.map(function (itemError, i) {
                                return <div key={i}>{itemError}</div>;
                            })}
                        </div>)
                    });
                    return false;
                }

                if (this.state.offerFor === "maker") {
                    this.setState({
                        submitting: true,
                    });
                    try {
                        // const resultCheck = await ocrApiService.getImageIdCardNumber({image_url: this.state.idCardImageUrl});
                        // const idCardNumberChecked = resultCheck?.data?.data?.id_card_number;
                        // if (idCardNumberChecked?.trim() !== this.state.idCardNumber?.trim()) {
                        //     console.log("error");
                        //     Modal.error({title: 'Proses Gagal', content: "Nomor KTP pada image berbeda dengan nomor KTP yang diinput"});
                        //     this.setState({
                        //         submitting: false,
                        //     });
                        //     return false;
                        // }

                        await ocrApiService.getImageIdCardNumber({image_url: this.state.idCardImageUrl});
                        this.setState({
                            submitting: false,
                        });
                    } catch (e) {
                        Modal.error({title: 'Proses Gagal', content: "KTP tidak terdeteksi, mohon upload image ktp secara utuh dan jelas"});
                        this.setState({
                            submitting: false,
                        });
                        return false;
                    }
                }
            }
        }

        this.setState({
            pageShow: data
        });
    }

    onClickCash = async () => {
        let warnNotice = "";
        if (warnNotice !== "") {
            Modal.error({
                title: 'Proses Gagal',
                content: "Mohon Lengkapi data-data berikut: \n" + warnNotice
            });
            return false;
        }

        const promoCodes = [];
        if (this.state.usedPromoData?.promoCode?.toUpperCase()) {
            promoCodes.push(this.state.usedPromoData?.promoCode?.toUpperCase());
        }

        let offerBroker = true;
        let offerAgentCode = this.state.agentCode;
        let offerAgentName = this.state.agentName;

        if (this.state.offerFor === 'owner') {
            offerBroker = false;
            offerAgentCode = "";
            offerAgentName = "";
        }

        let input: any = {
            company: "amarta",
            source: "amartahonda.com",
            purchase_method: "cash",
            amarta_vip_uid: this.state.amartaVipUid,
            amarta_vip_type: this.state.amartaVipType,
            area: this.state.areaCode,
            take_vehicle_in_dealer: this.state.takeInDealer,
            agent_code: offerAgentCode?.trim(),
            agent_name: offerAgentName?.trim(),
            phone_number_owner: this.state.phoneNumber?.trim(),
            phone_number_wa_available_owner: this.state.isWhatsappAvailable,
            phone_number_alternative_owner: this.state.alternativePhoneNumber?.trim(),
            phone_number_order_maker: this.state.phoneNumberOrderMaker?.trim(),
            promo_code: this.state.usedPromoData?.promoCode,
            promo_codes: promoCodes,
            offer_broker: offerBroker,
            notes: this.state.offerNote?.trim(),
            id_card_owner: {
                full_name: this.state.fullName?.trim(),
                id_card_number: this.state.idCardNumber?.trim(),
                id_card_image: this.state.idCardImageUrl,
                full_address: this.state.customerAddress?.trim(),
            },
            id_card_order_maker: {
                full_name: this.state.fullNameOrderMaker?.trim(),
            },
            address_owner: {
                full_address: this.state.customerAddress,
                province_code: this.state.customerProvinceCode,
                province_name: this.state.customerProvinceName,
                city_code: this.state.customerCityCode,
                city_name: this.state.customerCityName,
                district_name: this.state.customerDistrictName,
                district_code: this.state.customerDistrictCode,
                sub_district_code: this.state.customerSubdistrictCode,
                sub_district_name: this.state.customerSubdistrictName,
                zip_code: this.state.customerPostalCode?.trim(),
                hamlet: this.addLeadingZeros(this.state.customerHamlet, 3),
                neighbourhood: this.addLeadingZeros(this.state.customerNeighbourhood, 3)
            },
            family_register_owner: {
                family_register_number: this.state.familyCardNumber?.trim(),
            },
            vehicle: {
                brand_name: this.state.chosenNewVariantData.brand_name,
                brand_uuid: this.state.chosenNewVariantData.brand_uuid,
                model_name: this.state.chosenNewVariantColor.model_name,
                model_uuid: this.state.chosenNewVariantData.model_uuid,
                variant_name: this.state.chosenNewVariantColor.variant_name,
                variant_uuid: this.state.chosenNewVariantData.variant_uuid,
                variant_code: this.state.chosenNewVariantColor.variant_code,
                color: this.state.chosenNewVariantColor.color_name,
                color_code: this.state.chosenNewVariantColor.color_code,
                alternative_color: this.state.alternativeColor
            }
        };

        if (this.state.takeInDealer) {
        } else if (this.state.differentAddress) {
            input.address_shipping = {
                full_address: this.state.deliveryAddress?.trim(),
                province_code: this.state.deliveryProvinceCode,
                province_name: this.state.deliveryProvinceName,
                city_code: this.state.deliveryCityCode,
                city_name: this.state.deliveryCityName,
                district_name: this.state.deliveryDistrictName,
                district_code: this.state.deliveryDistrictCode,
                sub_district_code: this.state.deliverySubdistrictCode,
                sub_district_name: this.state.deliverySubdistrictName,
                zip_code: this.state.deliveryPostalCode?.trim(),
                hamlet: this.addLeadingZeros(this.state.deliveryHamlet, 3),
                neighbourhood: this.addLeadingZeros(this.state.deliveryNeighbourhood, 3)
            }
        } else {
            input.address_shipping = input.address_owner
        }

        this.setState({
            submitting: true,
            finalLoadingModal: true,
        });

        try {
            await offerApiServices.createOffer(
                input
            ).then(async successData => {
                await this.promisedSetState({
                    pageShow: 7,
                    offerCode: successData.data.data.offer_code,
                    finalLoadingModal: false,
                });
                // window.location.href = `/spk/${successData.data.data.offer_code}`;
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            this.setState({
                submitting: false,
                finalLoadingModal: false
            });
        }
    }

    async componentDidMount() {
        setTimeout(() => {
            window.scrollTo({top: 0, behavior: 'smooth'});
        }, 10);


        const loginData = await authApiService.isExistAmartaVipInternalAffiliateData();
        const amartaVipUid = (loginData.userUid) ? Buffer.from(loginData.userUid, 'base64').toString() : null;
        const amartaVipName = (loginData.userName) ? Buffer.from(loginData.userName, 'base64').toString() : null;
        const amartaVipType = (loginData.userType) ? Buffer.from(loginData.userType, 'base64').toString() : null;
        const amartaVipCode = (loginData.userAgentCode) ? Buffer.from(loginData.userAgentCode, 'base64').toString() : null;

        if (amartaVipUid) {
            await this.promisedSetState({
                amartaVipUid: amartaVipUid,
                amartaVipType: amartaVipType,
                agentCode: amartaVipCode,
                agentName: amartaVipName,
            });
        }

        trimitraCatalogServices.getCityGroup({
            name: this.areaCode
        }).then(value => {
            this.setState({
                customerProvinceCode: value.data.data[0]?.province_code ?? null,
                customerProvinceName: value.data.data[0]?.province_name ?? null,
                deliveryProvinceCode: value.data.data[0]?.province_code ?? null,
                deliveryProvinceName: value.data.data[0]?.province_name ?? null
            });
        });

        await trimitraCatalogServices.getCatalogVariant({
            areaCode: this.state.areaCode,
            variantCode: this.state.variantCode,
        }).then(dataVariantDetail => {
            const listColor: any = [];
            for (const item of dataVariantDetail.data.data) {
                listColor.push({
                    color_code: item?.color_code,
                    color_name: item?.color_name
                });
                if (item.color_code.toUpperCase() === this.state.variantColorCode.toUpperCase()) {
                    this.setState({
                        completeFetchVariant: true,
                        listNewVariantColor: dataVariantDetail.data.data,
                        chosenNewVariantColor: item
                    });
                }
            }

            this.setState({
                listColor: listColor
            });
        });

        await vehicleServices.getVehicleVariant({
            code: this.state.variantCode
        }).then(dataFetchModel => {
            this.setState({
                chosenNewVariantData: dataFetchModel.data.data[0]
            });
        });

        // get additional order cost
        await trimitraCatalogServices.getAdditionalCostOrder(
            {
                cityGroup: this.state.areaCode,
                purchaseMethod: 'cash',
                model: this.state.chosenNewVariantColor.model_name?.toLowerCase(),
            }
        ).then(dataFetchPromo => {
            if (dataFetchPromo?.data?.data?.active) {
                this.setState({
                    additionalOrderData: {
                        cost: dataFetchPromo?.data?.data?.additional_order_cost ?? 0,
                        note: dataFetchPromo?.data?.data?.note ?? "",
                    }
                });
            } else {
                this.setState({
                    additionalOrderData: {
                        cost: 0,
                        note: "",
                    }
                });
            }
        }).catch(e => {
            this.setState({
                additionalOrderData: {
                    cost: 0,
                    note: "",
                }
            });
        });

        // Check promo parameter from url query
        const queryParams = new URLSearchParams(window.location.search);
        const promoParam = queryParams.get('promo');
        if (promoParam) {
            promoServices.getDetailPromo(
                {
                    transactionAmount: this.state.chosenNewVariantColor.price,
                    promoCode: promoParam.trim(),
                    cityGroup: this.state.areaCode,
                    purchaseMethod: 'cash',
                    brand: 'honda',
                    model: this.state.chosenNewVariantColor.model_name?.toLowerCase(),
                    variant: this.state.chosenNewVariantColor.variant_code?.toUpperCase(),
                    amartaVipUid: this.state.amartaVipUid
                }
            ).then(dataFetchDetailPromo => {
                this.setState({
                    usedPromoData: dataFetchDetailPromo,
                    modalPromoShow: false,
                    showAgentCode: !!(dataFetchDetailPromo.showAgent),
                    agentCode: (dataFetchDetailPromo.showAgent) ? this.state.agentCode : "",
                });
                notification['success']({
                    message: 'Informasi Promo',
                    description: dataFetchDetailPromo?.note,
                    duration: 5
                });
            }).catch(e => {
                notification['warning']({
                    message: 'Informasi Promo',
                    description: e.toString() + ' / kode promo tidak memenuhi syarat, anda tetap dapat menggunakan promo lain pada form selanjutnya',
                    duration: 6
                });
            });
        }

        trackLogServices.sendTrackLog({
            eventName: `vehicle cash page`,
            eventSourceUrl: window.location.href,
            customData: null
        });
    }

    showModalPromo = () => {
        this.setState({
            modalPromoShow: true,
        })
    }

    closeModalPromo = () => {
        this.setState({
            usedPromoData: {},
            modalPromoShow: false,
        })
    }

    onPromoChange = (data: any) => {
        this.setState({
            usedPromoData: data,
            modalPromoShow: false,
            showAgentCode: !!(data.showAgent),
        });
    }

    onChangeAlternativeColor = async (code: string, text: string) => {
        this.setState({
            alternativeColor: {
                code: code,
                name: text,
            }
        });
    }

    onCropIdCardOwner = async (base64DataImagePng: any) => {
        await this.setState({
            base64IdCardOwner: base64DataImagePng,
        });
        // window.scrollTo({left: 0, top: document.body.scrollHeight, behavior: "smooth"});
    }

    saveOwnerIdCardData = async () => {
        if (this.state.base64IdCardOwner?.length < 10) {
            Modal.error({title: 'Error', content: "Terjadi error pada saat proses cropping, mohon lakukan upload image sekali lagi"});
            await this.promisedSetState({
                showOldUploadRawImage: true,
            });
            return;
        }

        const dataUpload = {
            id_image: this.state.idCardNumber,
            image_data: this.state.base64IdCardOwner,
            source: "amartahonda.com",
            notes: "cash forms"
        }

        await this.uploadImageAndUpdateState("IDCARDOWNER_IMAGE", dataUpload);
    }

    uploadImageAndUpdateState = async (type: string, dataUpload: any) => {
        if (type === "IDCARDOWNER_IMAGE") {
            if (!this.state.base64IdCardOwner) {
                Modal.error({title: 'Error', content: "Mohon Lengkapi Image KTP Pemilik"});
                return;
            }
        }

        await this.setState({
            freezeStatus: true,
        });

        try {
            await cdnApiService.uploadIdCardBase64(dataUpload).then(async successData => {
                if (type === "IDCARDOWNER_IMAGE") {
                    await this.promisedSetState({
                        idCardImageUrl: successData?.data?.data?.url_document,
                    });
                }

                await this.promisedSetState({
                    freezeStatus: false,
                    showModalUploadFamilyCard: false,
                    showModalUploadIdCardOwner: false,
                });
            });
        } catch (e) {
            Modal.error({
                title: 'Proses Gagal',
                content: 'Server Menolak Inputan Anda :' + e
            });
            await this.setState({
                freezeStatus: false
            });
        }
    }

    clickUpdateIdCardImage = () => {
        if (this.state.idCardNumber?.trim()?.length < 16) {
            Modal.error({title: 'Error', content: "Nomor KTP tidak valid"});
            return;
        }
        this.setState({showModalUploadIdCardOwner: true});
    }

    uploadImageChange = (data: any) => {
        if (data?.file?.status === "done") {
            const newImgLocation = data?.file?.response?.data?.url_document;
            this.setState({
                freezeStatus: false,
                idCardImageUrl: newImgLocation,
            });
        }
        if (data?.file?.status === "uploading") {
            this.setState({
                freezeStatus: true,
            });
        }
        if (data?.file?.status === "removed") {
            this.setState({
                freezeStatus: false,
                idCardImageUrl: "",
            });
        }
    }

    async onClickDuplicateProfile() {
        await this.promisedSetState({
            processFetchBiodata: true,
        });

        const isExistAmartaVipToken = await authApiService.isExistAmartaVipToken();
        if (!isExistAmartaVipToken) {
            await this.promisedSetState({
                showModalNeedLogin: true,
                processFetchBiodata: false,
                extraTextNeedLogin: " Atau tekan tombol cancel untuk melanjutkan proses pemesanan secara normal."
            });
            return;
        }

        try {
            await authApiService.setToken(isExistAmartaVipToken);
            const dataBiodata = await authApiService.getUserBiodata();
            const dataBio = dataBiodata?.data?.data;
            const dataIdCard = dataBio?.id_card;
            const dataAddress = dataBio?.address;
            // const dataCurrentAddress = dataBio?.current_address;
            const dataFamilyCard = (dataBio?.other_documents ?? []).find((o: any) => o.type === 'family_register') ?? {};

            this.setState({
                fullName: (dataIdCard?.full_name) ? dataIdCard?.full_name : "",
                idCardNumber: (dataIdCard?.id_card_number) ? dataIdCard?.id_card_number : "",
                idCardImageUrl: (dataIdCard?.id_card_image) ? dataIdCard?.id_card_image : "",
                familyCardNumber: (dataFamilyCard?.document_number) ? dataFamilyCard?.document_number : "",
                customerAddress: (dataAddress?.full_address) ? dataAddress?.full_address : "",
                customerProvinceCode: (dataAddress?.province_code) ? dataAddress?.province_code : "",
                customerProvinceName: (dataAddress?.province_name) ? dataAddress?.province_name : "",
                customerCityCode: (dataAddress?.city_code) ? dataAddress?.city_code : "",
                customerCityName: (dataAddress?.city_name) ? dataAddress?.city_name : "",
                customerDistrictCode: (dataAddress?.district_code) ? dataAddress?.district_code : "",
                customerDistrictName: (dataAddress?.district_name) ? dataAddress?.district_name : "",
                customerSubdistrictCode: (dataAddress?.sub_district_code) ? dataAddress?.sub_district_code : "",
                customerSubdistrictName: (dataAddress?.sub_district_name) ? dataAddress?.sub_district_name : "",
                customerPostalCode: (dataAddress?.postal_code) ? dataAddress?.postal_code : "",
                customerHamlet: (dataAddress?.hamlet) ? dataAddress?.hamlet : "",
                customerNeighbourhood: (dataAddress?.neighbourhood) ? dataAddress?.neighbourhood : "",
                processFetchBiodata: false
            });
        } catch (e) {
            await authApiService.removeAllToken();
            setTimeout(() => {
            }, 100);
            await authApiService.removeAllToken();

            setTimeout(() => {
                this.setState({
                    showModalNeedLogin: true,
                    processFetchBiodata: false,
                    extraTextNeedLogin: " Atau tekan tombol cancel untuk melanjutkan proses pemesanan secara normal."
                });
            }, 500);
        }
    }

    promisedSetState = (newState: any) => new Promise((resolve: any) => this.setState(newState, resolve));

    copyToClipboard = async (data: any) => {
        await navigator.clipboard.writeText(data);
        message.success('Nomor pengajuan SPK berhasil dicopy');
    }

    render() {
        const items: MenuProps['items'] = [
            {
                key: '1',
                label: (
                    <span onClick={() => this.onChangeAlternativeColor('SAME_AS_ORDER', 'warna sesuai pesanan')}>
                        warna sesuai pesanan
                    </span>
                ),
            },
            {
                key: '2',
                label: (
                    <span onClick={() => this.onChangeAlternativeColor('NO_PREFERENCE', 'tanpa prferensi warna')}>
                        tanpa prferensi warna
                    </span>
                ),
            },
        ];

        const contentInfoColor = (
            <div style={{fontSize: "85%", color: "gray"}}>
                <b>warna sesuai pesanan:</b> <span>warna kendaraan sesuai warna yang dipilih.</span><br/>
                <b>tanpa preferensi warna:</b> <span>warna kendaraan sesuai dengan stock yang tersedia di dealer. (memungkinkan warna random)</span>
            </div>
        );

        return (
            <React.Fragment>
                {this.state.completeFetchVariant &&
                    <React.Fragment>
                        <div style={{display: (this.state.pageShow === 1) ? '' : 'none'}}>
                            <SimpleLayout headerTitle={`Pembelian Tunai ${this.state.areaCode?.toUpperCase()}`} linkHeaderBack={`/baru/${this.state.areaCode}/${this.state.variantCode.replace('/', '')}`}>
                                <div className={`content-top-padding-20 height-100`}>
                                    <Row>
                                        <Col span={20}>
                                            <ResumeMiniProduct
                                                modelName={this.state.chosenNewVariantColor.model_name}
                                                variantCode={this.state.chosenNewVariantColor.variant_code}
                                                variantName={this.state.chosenNewVariantColor.variant_name}
                                                variantColorCode={this.state.chosenNewVariantColor.color_code}
                                                variantColorName={this.state.chosenNewVariantColor.color_name}
                                                linkTarget={`/baru/${this.state.areaCode}/${this.state.variantCode.replace('/', '')}`}
                                            />
                                            <Row style={{marginTop: 5}}>
                                                <Col span={9} className={`text-small-green-nopadding`} style={{cursor: "pointer"}}>
                                                    <Popover content={contentInfoColor} trigger="hover">
                                                        Warna Alternatif
                                                        <InfoCircleOutlined style={{fontSize: "100%", paddingLeft: 5}}/>
                                                    </Popover>
                                                </Col>
                                                <Col span={13}>
                                                    <Dropdown menu={{items}}>
                                                        <Space style={{cursor: "pointer"}} className={`text-small-grey-nopadding`}>
                                                            {this.state.alternativeColor?.name}
                                                            <DownOutlined/>
                                                        </Space>
                                                    </Dropdown>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={4}>
                                            <div style={{paddingTop: 30}}>
                                                <Image alt={`variant color`} src={this.state.chosenNewVariantColor.url_image} style={{width: "100%"}}/>
                                            </div>
                                        </Col>
                                    </Row>
                                    <br/>

                                    <Divider/>

                                    <Tag style={this.state.additionalOrderData?.cost ? {marginBottom: '10px'} : {display: 'none'}} icon={<ExclamationCircleOutlined/>} color="processing">
                                        {this.state.additionalOrderData?.note} {currencyFormat(this.state.additionalOrderData?.cost)}
                                    </Tag>

                                    <br/>

                                    <Form layout="vertical">
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Mengajukan Pembelian Untuk</i>
                                            <Select
                                                placeholder="Pilih Pengajuan Kredit"
                                                showSearch
                                                optionFilterProp="children"
                                                onChange={value => this.onFieldChange("offerFor", value)}
                                                value={this.state.offerFor}
                                            >
                                                <Select.Option value="owner">Saya Sendiri</Select.Option>
                                                <Select.Option disabled={true} value="maker">Orang Lain</Select.Option>
                                            </Select>
                                        </Form.Item>

                                        <div style={this.state.offerFor === 'maker' ? {} : {display: 'none'}}>
                                            <Form.Item>
                                                <i className={`text-small-grey-nopadding`}>Kode Agent (tidak wajib diisi)</i>
                                                <Input disabled={true} value={(this.state.agentCode) ? `${this.state.agentCode} - ${this.state.agentName}` : `Akun Anda Tidak Memiliki Kode Agent`}/>
                                            </Form.Item>
                                            <Form.Item>
                                                <i className={`text-small-grey-nopadding`}>Nama Pemesan</i>
                                                <Input value={this.state.fullNameOrderMaker} onChange={event => this.onFieldChange("fullNameOrderMaker", event.target.value)} placeholder="Masukan Nama Pemesan"/>
                                            </Form.Item>
                                            <Form.Item>
                                                <i className={`text-small-grey-nopadding`}>Nomor Telepon Pemesan</i>
                                                <Input
                                                    value={this.state.phoneNumberOrderMaker}
                                                    maxLength={17}
                                                    placeholder="Format: 08xxxxxxxx"
                                                    onChange={event => this.onFieldChange("phoneNumberOrderMaker", event.target.value)}
                                                />
                                            </Form.Item>
                                        </div>

                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Nomor Telepon Pemilik *</i>
                                            <Input
                                                value={this.state.phoneNumber}
                                                maxLength={17}
                                                placeholder="Format: 08xxxxxxxx"
                                                onChange={event => this.onFieldChange("phoneNumber", event.target.value)}
                                            />
                                            <Checkbox onChange={e => this.onBooleanFieldChange("isWhatsappAvailable", e.target.checked)} checked={this.state.isWhatsappAvailable}>
                                                <i className={`text-small-grey-nopadding`}>Ini juga merupakan nomor Whatsapp.</i>
                                            </Checkbox>
                                        </Form.Item>
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Nomor Telepon Alternatif (Opsional)</i>
                                            <Input
                                                value={this.state.alternativePhoneNumber}
                                                maxLength={17}
                                                placeholder="Format: 08xxxxxxxx"
                                                onChange={event => this.onFieldChange("alternativePhoneNumber", event.target.value)}
                                            />
                                        </Form.Item>
                                    </Form>
                                </div>
                            </SimpleLayout>
                            <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}} style={{position: "fixed", zIndex: 500, width: '100%', bottom: 0, backgroundColor: '#fff', paddingTop: 10, paddingBottom: 10, paddingLeft: '0.5%', paddingRight: '0.5%', boxShadow: '0px 2px 7px 2px rgb(208 208 208)'}}>
                                <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} block={true} loading={this.state.submitting} onClick={() => this.onClickNext(2)} type="primary"> Mulai Pengajuan</Button>
                            </Col>
                        </div>

                        <div style={{display: (this.state.pageShow === 2) ? '' : 'none'}}>
                            <CashSubmissionLayout headerTitle={`Pembelian Tunai ${this.state.areaCode?.toUpperCase()}`}>
                                <div className={`content-top-padding-20`}>
                                    <div className={`content-top-padding-20-green`}>ALAMAT & PENGIRIMAN (2/3)</div>
                                    <Row style={{display: (this.state.amartaVipUid) ? '' : 'none'}}>
                                        <Col span={24} style={{textAlign: "center", paddingTop: 10, paddingBottom: 10}}>
                                            <Button loading={this.state.processFetchBiodata} onClick={() => {
                                                this.onClickDuplicateProfile()
                                            }} style={{width: "100%", height: 65, borderRadius: 10}}>
                                                <span style={{fontSize: "105%", color: "#000", fontWeight: 500}}><SnippetsOutlined/> Gunakan Biodata Saya</span><br/>
                                                <span style={{fontSize: "85%", color: "gray"}}>Gunakan Data Biodata saya sebagai data pemilik kendaraan</span>
                                            </Button>
                                        </Col>
                                    </Row>

                                    <Form layout="vertical">
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Nomor KTP *</i>
                                            <Input
                                                maxLength={16}
                                                value={this.state.idCardNumber}
                                                placeholder="Nomor KTP"
                                                onChange={event => this.onFieldChange("idCardNumber", event.target.value)}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Nama Lengkap *</i>
                                            <Input value={this.state.fullName} onChange={event => this.onFieldChange("fullName", event.target.value)} placeholder="Masukan Nama Sesuai KTP Pemilik"/>
                                        </Form.Item>

                                        <div className={`card-box-shadow`}>
                                            <Row>
                                                <Col span={24}>
                                                    <IdcardOutlined className={`icon-title-info`}/>
                                                    <b className={`text-title-info`}>KTP</b>
                                                </Col>
                                            </Row>
                                            <Row style={{paddingTop: 10}}>
                                                <Col span={23} offset={1}>
                                                    <Row>
                                                        <Col span={12}>
                                                            <div className="font-old-grey-100">
                                                                <i className={`text-extra-small-grey-nopadding`}>Nomor KTP:</i>
                                                                <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.idCardNumber} </div>
                                                                <i className={`text-extra-small-grey-nopadding`}>Nama:</i>
                                                                <div style={{marginTop: -2}} className={`text-small-black-nopadding`}> {this.state.fullName} </div>
                                                            </div>
                                                        </Col>
                                                        <Col span={12}>
                                                            {this.state.idCardImageUrl
                                                                ? <div style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image style={{height: 80}} src={this.state.idCardImageUrl}/></div>
                                                                : <div onClick={() => this.clickUpdateIdCardImage()} style={{float: "right", marginRight: 20, cursor: "pointer"}}><Image preview={false} style={{height: 80}} src={'/assets/img/uploadImage.png'}/></div>
                                                            }
                                                        </Col>
                                                    </Row>
                                                </Col>
                                                <Col span={23} offset={1} className="pt-5" style={{cursor: "pointer"}}>
                                                    <div onClick={() => this.clickUpdateIdCardImage()} className="text-small-green-nopadding" style={{textAlign: "right", fontWeight: 600}}>
                                                        {this.state.idCardImageUrl
                                                            ? <span>Update Gambar KTP</span>
                                                            : <span>Lengkapi Gambar KTP</span>
                                                        }
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                        <br/>
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Nomor Kartu Keluarga *</i>
                                            <Input
                                                maxLength={16}
                                                value={this.state.familyCardNumber}
                                                placeholder="Masukan Nomor KK Anda"
                                                onChange={event => this.onFieldChange("familyCardNumber", event.target.value)}
                                            />
                                        </Form.Item>
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Alamat Pemilik *</i>
                                            <TextArea showCount maxLength={200} value={this.state.customerAddress} onChange={event => this.onFieldChange("customerAddress", event.target.value)} placeholder="Alamat"/>
                                        </Form.Item>
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Provinsi Pemilik *</i>
                                            <AreaProvinceSelect onAreaProvinceChangeProps={this.onAreaProvinceChange} country={"idn"} provinceName={this.state.customerProvinceName}/>
                                        </Form.Item>
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Kota Pemilik *</i>
                                            <AreaCitySelect onAreaCityChangeProps={this.onAreaCityChange} provinceCode={this.state.customerProvinceCode} cityName={this.state.customerCityName}/>
                                        </Form.Item>
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Kecamatan Pemilik *</i>
                                            <AreaDistrictSelect onAreaDistrictChangeProps={this.onAreaDistrictChange} cityCode={this.state.customerCityCode} districtName={this.state.customerDistrictName}/>
                                        </Form.Item>
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Kelurahan Pemilik *</i>
                                            <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onAreaSubdistrictChange} districtCode={this.state.customerDistrictCode} subdistrictName={this.state.customerSubdistrictName}/>
                                        </Form.Item>
                                        <Form.Item>
                                            <i className={`text-small-grey-nopadding`}>Kode Pos Pemilik *</i>
                                            <Input disabled={this.state.customerDisablePostalCode} value={this.state.customerPostalCode} onChange={event => this.onFieldChange("customerPostalCode", event.target.value)} placeholder="Kode Pos"/>
                                        </Form.Item>

                                        <Row>
                                            <Col span={12} style={{paddingRight: '2px'}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>RT</i>
                                                    <Input maxLength={3} value={this.state.customerHamlet} onChange={event => this.onFieldChange("customerHamlet", event.target.value)} placeholder="RT"/>
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} style={{paddingLeft: '2px'}}>
                                                <Form.Item>
                                                    <i className={`text-small-grey-nopadding`}>RW</i>
                                                    <Input maxLength={3} value={this.state.customerNeighbourhood} onChange={event => this.onFieldChange("customerNeighbourhood", event.target.value)} placeholder="RW"/>
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Form.Item>
                                            <Checkbox onChange={e => this.onBooleanFieldChange("takeInDealer", e.target.checked)} checked={this.state.takeInDealer}>
                                                Saya ingin mengambil unit di dealer.
                                            </Checkbox>
                                            <br/><br/>
                                            <Checkbox onChange={e => this.onBooleanFieldChange("differentAddress", e.target.checked)} checked={this.state.differentAddress}>
                                                Alamat pengiriman berbeda dengan alamat pemilik.
                                            </Checkbox>
                                        </Form.Item>

                                        <div style={{display: (this.state.differentAddress) ? 'block' : 'none'}}>
                                            <Form.Item>
                                                <i className={`text-small-grey-nopadding`}>Alamat Pengiriman</i>
                                                <TextArea showCount maxLength={200} value={this.state.deliveryAddress} onChange={event => this.onFieldChange("deliveryAddress", event.target.value)} placeholder="Alamat Pengiriman"/>
                                            </Form.Item>
                                            <Form.Item>
                                                <i className={`text-small-grey-nopadding`}>Provinsi Pengiriman</i>
                                                <AreaProvinceSelect onAreaProvinceChangeProps={this.onDeliveryProvinceChange} country={"idn"} provinceName={this.state.deliveryProvinceName}/>
                                            </Form.Item>
                                            <Form.Item>
                                                <i className={`text-small-grey-nopadding`}>Kota Pengiriman</i>
                                                <AreaCitySelect onAreaCityChangeProps={this.onDeliveryCityChange} provinceCode={this.state.deliveryProvinceCode}/>
                                            </Form.Item>
                                            <Form.Item>
                                                <i className={`text-small-grey-nopadding`}>Kecamatan Pengiriman</i>
                                                <AreaDistrictSelect onAreaDistrictChangeProps={this.onDeliveryDistrictChange} cityCode={this.state.deliveryCityCode}/>
                                            </Form.Item>
                                            <Form.Item>
                                                <i className={`text-small-grey-nopadding`}>Kelurahan Pengiriman</i>
                                                <AreaSubdistrictSelect onAreaSubdistrictChangeProps={this.onDeliverySubdistrictChange} districtCode={this.state.deliveryDistrictCode}/>
                                            </Form.Item>
                                            <Form.Item>
                                                <i className={`text-small-grey-nopadding`}>Kode Pos</i>
                                                <Input disabled={this.state.deliveryDisablePostalCode} value={this.state.deliveryPostalCode} onChange={event => this.onFieldChange("deliveryPostalCode", event.target.value)} placeholder="Kode Pos"/>
                                            </Form.Item>
                                            <Row>
                                                <Col span={12} style={{paddingRight: '2px'}}>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>RT</i>
                                                        <Input maxLength={3} value={this.state.deliveryHamlet} onChange={event => this.onFieldChange("deliveryHamlet", event.target.value)} placeholder="RT"/>
                                                    </Form.Item>
                                                </Col>
                                                <Col span={12} style={{paddingLeft: '2px'}}>
                                                    <Form.Item>
                                                        <i className={`text-small-grey-nopadding`}>RW</i>
                                                        <Input maxLength={3} value={this.state.deliveryNeighbourhood} onChange={event => this.onFieldChange("deliveryNeighbourhood", event.target.value)} placeholder="RW"/>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </div>
                                        <Row style={{paddingBottom: 25, marginTop: 25}}>
                                            <Col span={12}>
                                                <Button loading={this.state.submitting} block={true} onClick={() => this.onClickNext(1)}> Kembali</Button>
                                            </Col>
                                            <Col span={12}>
                                                <Button loading={this.state.submitting} block={true} onClick={() => this.onClickNext(3)} style={{marginLeft: 3, backgroundColor: "#64b48f", borderColor: "#64b48f"}} type="primary"> Lanjut</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </CashSubmissionLayout>
                        </div>

                        <div style={{display: (this.state.pageShow === 3) ? '' : 'none'}}>
                            <CashSubmissionLayout headerTitle={`Pembelian Tunai ${this.state.areaCode?.toUpperCase()}`}>
                                <div className={`content-top-padding-20 height-100`}>
                                    <div className={`content-top-padding-20-green`}>INFO & PROMO (3/3)</div>

                                    <Card size="small" title="Data Kendaraan" className={`card-shadow`}>
                                        <Row>
                                            <Col span={19}>
                                                <Typography.Text type="secondary" style={{paddingRight: 46}}> Model: </Typography.Text> {this.state.chosenNewVariantColor.model_name} <br/>
                                                <Typography.Text type="secondary" style={{paddingRight: 43}}> Variant: </Typography.Text> {this.state.chosenNewVariantColor.variant_name} <br/>
                                                <Typography.Text type="secondary" style={{paddingRight: 8}}> Kode Variant: </Typography.Text> {this.state.chosenNewVariantColor.variant_code} <br/>
                                                <Typography.Text type="secondary" style={{paddingRight: 47}}> Warna: </Typography.Text> {this.state.chosenNewVariantColor.color_name} ({this.state.chosenNewVariantColor.color_code}) <br/>
                                                <Typography.Text type="secondary" style={{paddingRight: 19}}> Harga OTR: </Typography.Text> {currencyFormat(this.state.chosenNewVariantColor.price)} <br/>
                                                <Typography.Text type="secondary" style={{paddingRight: 38}}> Metode: </Typography.Text> Tunai <br/>
                                            </Col>
                                            <Col span={5}>
                                                <Image alt={`variant color`} src={this.state.chosenNewVariantColor.url_image} style={{width: "100%"}}/>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card size="small" title="Kepemilikan Kendaraan" className={`card-shadow`}>
                                        <Row>
                                            <Col span={6}><Typography.Text type="secondary"> Nomor KTP: </Typography.Text></Col>
                                            <Col span={18}> {this.state.idCardNumber}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}><Typography.Text type="secondary"> Nomor KK: </Typography.Text></Col>
                                            <Col span={18}> {this.state.familyCardNumber}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}><Typography.Text type="secondary"> Nama: </Typography.Text></Col>
                                            <Col span={18}> {this.state.fullName}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}><Typography.Text type="secondary"> Telpon: </Typography.Text></Col>
                                            <Col span={18}> {this.state.phoneNumber}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}><Typography.Text type="secondary"> Alamat: </Typography.Text></Col>
                                            <Col span={18}> {this.state.customerAddress}</Col>
                                        </Row>
                                        <Row>
                                            <Col span={6}>&nbsp;</Col>
                                            <Col span={18}>
                                                {this.state.customerCityName?.toUpperCase()} - {this.state.customerDistrictName?.toUpperCase()}, {this.state.customerProvinceName?.toUpperCase()} {this.state.customerPostalCode}
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Card size="small" title="Penyerahan Kendaraan" className={`card-shadow`}>
                                        <Row style={(!this.state.differentAddress && !this.state.takeInDealer) ? {} : {display: 'none'}}>
                                            <Col span={6}><Typography.Text type="secondary"> Alamat: </Typography.Text></Col>
                                            <Col span={18}>
                                                {this.state.customerAddress}<br/>
                                                {this.state.customerCityName?.toUpperCase()} - {this.state.customerDistrictName?.toUpperCase()}, {this.state.customerProvinceName?.toUpperCase()} {this.state.customerPostalCode}
                                            </Col>
                                        </Row>

                                        <Row style={(this.state.differentAddress && !this.state.takeInDealer) ? {} : {display: 'none'}}>
                                            <Col span={6}><Typography.Text type="secondary"> Alamat: </Typography.Text></Col>
                                            <Col span={18}>
                                                {this.state.deliveryAddress}<br/>
                                                {this.state.deliveryCityName?.toUpperCase()} - {this.state.deliveryDistrictName?.toUpperCase()}, {this.state.deliveryProvinceName?.toUpperCase()} {this.state.deliveryPostalCode}
                                            </Col>
                                        </Row>

                                        <Row style={this.state.takeInDealer ? {} : {display: 'none'}}>
                                            <Col span={6}>&nbsp;</Col>
                                            <Col span={18}>
                                                Diambil di delaer kota {this.state.areaCode?.toUpperCase()}
                                            </Col>
                                        </Row>
                                    </Card>

                                    <Card size="small" title="Promo" className={`card-shadow`}>
                                        <Row>
                                            <Col span={24}>
                                                <Input value={this.state.usedPromoData?.promoCode} onClick={this.showModalPromo} style={{cursor: "pointer"}} readOnly={true} size="large" placeholder="Lebih Hemat Dengan Promo" prefix={<SearchOutlined className={'grey-color'}/>}/>
                                                <div style={this.state.usedPromoData?.note ? {} : {display: 'none'}}>
                                                    <br/>
                                                    <i><Typography.Text mark>{this.state.usedPromoData?.note}</Typography.Text></i>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Card>
                                    <Row style={{paddingBottom: 10, marginTop: 30}}>
                                        <Col span={12}>
                                            <Button loading={this.state.submitting} block={true} onClick={() => this.onClickNext(2)}> Kembali</Button>
                                        </Col>
                                        <Col span={12}>
                                            <Button loading={this.state.submitting} block={true} onClick={() => this.onClickCash()} type="primary" style={{marginLeft: 3, backgroundColor: "#64b48f", borderColor: "#64b48f"}}> Ajukan Pembelian Tunai</Button>
                                        </Col>
                                    </Row>

                                </div>
                            </CashSubmissionLayout>
                        </div>

                        <div style={{display: (this.state.pageShow === 7) ? '' : 'none'}}>
                            <CashSubmissionLayout headerTitle={`Pembelian Tunai ${this.state.areaCode?.toUpperCase()}`}>
                                <div className={`content-top-padding-20`}>
                                    <Result
                                        status="success"
                                        title="Pengisian Data Sukses"
                                        subTitle="Silakan download dokumen pemesanan (opsional) dan lanjutkan ke proses invoice SPK"
                                    />
                                </div>

                                Nomor Pengajuan: {this.state.offerCode}
                                <Tooltip title="Click untuk copy nomor pengajuan SPK" color={'cyan'}>
                                    <CopyOutlined style={{color: "#259261", paddingLeft: 7}} className={'small-avatar-link'} onClick={event => this.copyToClipboard(this.state.offerCode)}/>
                                </Tooltip> <br/> <br/>

                                <Alert
                                    message="Informasi"
                                    description={`Untuk memudahkan pelacakan dan informasi terkait transaksi anda, kami menghimbau anda untuk menyimpan nomor PENGAJUAN SPK atau melakukan DOWNLOAD PDF pengajuan. click untuk copy`}
                                    type="info"
                                    style={{cursor: "pointer"}}
                                    showIcon
                                    onClick={event => this.copyToClipboard(this.state.offerCode)}
                                /><br/>

                                <a href={`https://asia-southeast1-autotrimitra.cloudfunctions.net/api-offer-otodis--create-pdf/spk/generate-pdf?company=amarta&spk_code=${this.state.offerCode}&token=asc8asdasaskjdhas87asdasd&force_download=true`} target={'_blank'} rel="noreferrer">
                                    <Button><DownloadOutlined/> Donwload PDF</Button>
                                </a>

                                <br/><br/><br/>
                            </CashSubmissionLayout>

                            <Col xl={{span: 7, offset: 9}} lg={{span: 10, offset: 9}} md={{span: 10, offset: 7}} style={{position: "fixed", zIndex: 500, width: '100%', bottom: 0, backgroundColor: '#fff', paddingTop: 10, paddingBottom: 10, paddingLeft: '0.5%', paddingRight: '0.5%', boxShadow: '0px 2px 7px 2px rgb(208 208 208)'}}>
                                <Link to={`/spk/${this.state.offerCode}`}>
                                    <Button style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} block={true} type="primary"> SPK dan Pembayaran</Button>
                                </Link>
                            </Col>

                        </div>

                    </React.Fragment>
                }
                {!this.state.completeFetchVariant &&
                    <SimpleLayout headerTitle={`Pembelian Tunai ${this.state.areaCode?.toUpperCase()}`} linkHeaderBack={`/baru/${this.state.areaCode}/${this.state.variantCode.replace('/', '')}`}>
                        <div className="loading">
                            <Spin tip="Loading..."/>
                        </div>
                    </SimpleLayout>
                }

                <Modal
                    title="Promo"
                    maskClosable={false}
                    open={this.state.modalPromoShow}
                    onOk={this.closeModalPromo}
                    onCancel={this.closeModalPromo}
                    footer={null}
                >
                    <ListPromoModal
                        onPromoChangeProps={this.onPromoChange}
                        company={"amarta"}
                        purchaseMethod={"cash"}
                        area={this.state.areaCode}
                        vehicle={this.state.chosenNewVariantColor}
                        alternativeColor={this.state.alternativeColor?.code}
                        amartaVipUid={this.state.amartaVipUid}
                    />
                </Modal>

                <Modal
                    open={this.state.finalLoadingModal}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

                <Modal
                    title="Lengkapi Data KTP"
                    style={{top: 2}}
                    maskClosable={false}
                    open={this.state.showModalUploadIdCardOwner}
                    onCancel={() => this.setState({showModalUploadIdCardOwner: false})}
                    footer={[
                        <Button style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}} key={'saveCustomer'} type="primary" onClick={this.saveOwnerIdCardData}> Simpan </Button>,
                        <Button key={'closeCustomer'} type="default" onClick={() => this.setState({showModalUploadIdCardOwner: false})}> Close </Button>,
                    ]}
                >
                    <Form layout="vertical" style={!this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Collapse collapsible="header" defaultActiveKey={['1']}>
                            <Panel header="Cara Melengkapi Data" key={(this.state.base64IdCardOwner) ? "0" : "1"}>
                                <ul style={{fontSize: "80%", paddingLeft: 20}}>
                                    <li>Klik Tombol Upload Gambar KTP Pemilik</li>
                                    <li>Jika gambar telah sesuai, klik Tombol Crop Image</li>
                                    <li>Tekan Tombol Simpan</li>
                                </ul>
                            </Panel>
                        </Collapse><br/>
                        <Form.Item>
                            <CropIdCardImage params={this.state} onCropImage={this.onCropIdCardOwner}/>
                        </Form.Item>
                    </Form>

                    <Form layout="vertical" style={this.state.showOldUploadRawImage ? {} : {display: 'none'}}>
                        <Upload
                            method="POST"
                            action={baseUrlCdnUpload + "/upload-document/id-card"}
                            listType="picture"
                            maxCount={1}
                            data={{
                                id_card_number: this.state.idCardNumber,
                                source: "amartahonda.com",
                                notes: "cash forms"
                            }}
                            name="image"
                            onChange={this.uploadImageChange}
                        > <Button icon={<UploadOutlined/>}>Upload Ulang Gambar KTP</Button>
                        </Upload>
                    </Form>
                </Modal>

                <Modal
                    open={this.state.freezeStatus}
                    closeIcon={true}
                    footer={null}
                >
                    <div style={{textAlign: "center", padding: 20, minHeight: 100}}>
                        <Spin style={{marginTop: 20}} size={'large'} tip="Processing..."/>
                    </div>

                    <div style={{textAlign: "center", padding: 20}}>
                        Mohon menunggu beberapa saat, kami sedang memproses permintaan anda.
                    </div>
                </Modal>

                <Modal
                    title={"Login Dibutuhkan"}
                    open={this.state.showModalNeedLogin}
                    onCancel={() => {
                        this.setState({showModalNeedLogin: false})
                    }}
                    onOk={() => {
                        this.setState({showModalNeedLogin: false})
                    }}
                    footer={[
                        <Button key="login" type={"primary"} style={{backgroundColor: "#64b48f", borderColor: "#64b48f"}} onClick={() => {
                            window.location.href = "/login?internal-page-source=" + window.location.href;
                        }}>Login / Daftar AmartaVIP</Button>,
                        <Button key="close" onClick={() => {
                            this.setState({showModalNeedLogin: false})
                        }}>Cancel</Button>
                    ]}
                >
                    Anda perlu login terlebih dahulu. {this.state.extraTextNeedLogin}
                </Modal>

            </React.Fragment>
        );
    }
}

export default withRouter(NewVehicleVariantDetailCash);
