import axios from "axios";
import {baseUrlOffer, xApiKeyOffer} from "../../config/apiConfig/apiConfig";

const companyCode = 'amarta';

class OfferApiServices {
    private axios = axios.create({
        baseURL: baseUrlOffer,
    });

    public async getPublicOffer(params?: any) {
        try {
            return await this.axios.get<any>(`/public/offer/${companyCode}/${params.offerCode}`, {
                headers: {
                    "X-Api-Key": xApiKeyOffer,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getDetailOffer(params?: any) {
        try {
            return await this.axios.get<any>(`/offer/${companyCode}/${params.offerCode}`, {
                headers: {
                    "X-Api-Key": xApiKeyOffer,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getPaymentHistoryOffer(params?: any) {
        try {
            return await this.axios.get<any>(`/bill/fin/${companyCode}/${params.offerCode}`, {
                headers: {
                    "X-Api-Key": xApiKeyOffer,
                }
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async getOffer(params?: any) {
        const queries = {
            ...(params?.startDate && {
                start_date: params.startDate,
            }),
            ...(params?.endDate && {
                end_date: params.endDate,
            }),
            ...(params?.area && {
                area: params.area.toLowerCase(),
            }),
            ...(params?.status && {
                status: params.status.toLowerCase(),
            }),
        }
        try {
            return await this.axios.get<any>(`/offer/${companyCode}`, {
                headers: {
                    "X-Api-Key": xApiKeyOffer,
                },
                params: {
                    ...queries,
                }
            });
        } catch (e: any) {
            throw new Error(e);
        }
    }

    public async createOffer(inputData?: any) {
        try {
            return await this.axios.post<any>('/create-offer',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyOffer
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async createInvoice(inputData?: any) {
        try {
            return await this.axios.post<any>('/create-invoice',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyOffer
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async acquisitionOffer(inputData?: any) {
        try {
            return await this.axios.post<any>('/acquisition-offer',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyOffer
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async acquisitionEpoOffer(inputData?: any) {
        try {
            return await this.axios.post<any>('/acquisition-epo-offer',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyOffer
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async createB2bCreditOffer(inputData?: any) {
        try {
            return await this.axios.post<any>('/create-b2b-credit-offer',
                inputData, {
                    headers: {
                        "Content-Type": "application/json",
                        "X-Api-Key": xApiKeyOffer
                    }
                });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

    public async updateOffer(inputData?: any) {
        try {
            return await this.axios.put<any>("/update-offer", inputData, {
                headers: {
                    "Content-Type": "application/json",
                    "X-Api-Key": xApiKeyOffer,
                },
            });
        } catch (e: any) {
            throw new Error(e.response.data.error.message);
        }
    }

}

export const offerApiServices = new OfferApiServices();
