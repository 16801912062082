import {Link} from "react-router-dom";

// import Cookies from 'universal-cookie';
// import {Buffer} from "buffer";
// const cookies = new Cookies();
// const cookieValue = cookies?.get('_amh-ut');
// let userType = "";
// if (cookieValue) {
//     const buffer = Buffer.from(cookieValue, 'base64');
//     userType = buffer.toString();
// }

let MenuConfig: any = [
    {
        key: '1',
        label: (<Link to={`/my-profile`}>
            <div style={{minWidth: 160}}>My Profile</div>
        </Link>),
    },
    {
        key: '2',
        label: (<Link to={`/my-biodata`}>
            <div style={{minWidth: 160}}>My Biodata</div>
        </Link>),
    },
    {
        key: '3',
        label: (<Link to={`/my-order`}>
            <div style={{minWidth: 160}}>My Order</div>
        </Link>),
    },
    {
        key: '4',
        label: (<Link to={`/my-promo`}>
            <div style={{minWidth: 160}}>My Promo</div>
        </Link>),
    },
    {
        key: '5',
        label: (<Link to={`/my-bookmark`}>
            <div style={{minWidth: 160}}>My Bookmark</div>
        </Link>),
    },
    {
        key: '6',
        label: (<Link to={`/my-deal`}>
            <div style={{minWidth: 160}}>My Deal</div>
        </Link>),
    },
    // {
    //     key: '7',
    //     label: (<Link to={`/express`}>
    //         <div style={{minWidth: 160}}>Express (Akuisisi)</div>
    //     </Link>),
    // },
    // {
    //     key: '8',
    //     label: (<Link to={`/adira-express`}>
    //         <div style={{minWidth: 160}}>Adira Express</div>
    //     </Link>),
    // },
    // {
    //     key: '9',
    //     label: (<Link to={`/order-memo`}>
    //         <div style={{minWidth: 160}}>Order Memo</div>
    //     </Link>),
    // }
];

export default MenuConfig;